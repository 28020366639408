import React from 'react';
import PropTypes from 'prop-types';
import AppLoaded from '../../../common/components/app-loaded';
import EmptyStates from '../../../common/components/empty-states';
import {
  FullHeightLayout,
  SimpleLayout,
} from '../../../common/components/layout';
import Page from '../../../common/components/page';
import forDevice from '../../../common/hoc/for-device';
import withTranslate from '../../../common/hoc/with-translate';
import styles from './no-post-found.scss';

const Layout = forDevice(FullHeightLayout, SimpleLayout);

const NoPostFound = ({ t }) => (
  <Page noSpacing>
    <Layout>
      <EmptyStates
        title={t('no-post-found.title')}
        content={t('no-post-found.message')}
        className={styles.container}
        titleClassName={styles.title}
      />
    </Layout>
    <AppLoaded />
  </Page>
);

NoPostFound.propTypes = {
  t: PropTypes.func.isRequired,
};

export default withTranslate(NoPostFound);
