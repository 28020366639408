import React, { Component } from 'react';
import classNames from 'classnames';
import { flowRight } from 'lodash';
import PropTypes from 'prop-types';
import { TooltipPortal } from '../../context/tooltip-context';
import withFontClassName from '../../hoc/with-font-class-name';
import { isSite } from '../../store/basic-params/basic-params-selectors';
import { connect } from '../runtime-context';
import styles from './button-tooltip.scss';

class ButtonTooltip extends Component {
  state = {
    position: { top: 0, left: 0 },
  };

  componentDidUpdate(prevProps) {
    const { isVisible, isSite } = this.props;

    if (isVisible !== prevProps.isVisible) {
      if (isVisible) {
        isSite ? this.showComponent() : this.showComponentInEditor();
      }
    }
  }

  showComponentInEditor() {
    if (!this.container || !this.props.target) {
      return;
    }

    const topMargin = 12;
    const leftMargin = 19;
    const rect = this.container.getBoundingClientRect();
    const targetRect = this.target.getBoundingClientRect();

    const top = targetRect.top - (topMargin + rect.height);
    let left =
      targetRect.right - targetRect.width / 2 + leftMargin - rect.width;
    let arrowPosition;

    if (left < 0) {
      left = targetRect.right - targetRect.width / 2 - leftMargin;
      arrowPosition = styles.arrowleft;
    }

    this.setState({ position: { top, left }, arrowPosition });
  }

  showComponent() {
    if (!this.container || !this.props.target) {
      return;
    }

    const rect = this.container.getBoundingClientRect();
    const targetRect = this.props.target.getBoundingClientRect();
    const rootRect = document
      .getElementById(this.props.componentId)
      .getBoundingClientRect();

    const relativeRect = {
      top: targetRect.top - rootRect.top,
      right: targetRect.right - rootRect.left,
      width: targetRect.width,
    };

    const top = relativeRect.top - (12 + rect.height);
    let left = relativeRect.right - relativeRect.width / 2 + 19 - rect.width;
    let arrowPosition;

    if (left < 0) {
      left = relativeRect.right - relativeRect.width / 2 - 19;
      arrowPosition = styles.arrowleft;
    }

    this.setState({ position: { top, left }, arrowPosition });
  }

  setContainer = (container) => {
    this.container = container;
  };

  render() {
    const { contentFontClassName, children, isVisible } = this.props;
    const { position, arrowPosition } = this.state;

    const containerClassName = classNames(
      styles.bubble,
      contentFontClassName,
      arrowPosition,
      'blog-text-color',
      'blog-card-border-color',
      'blog-card-background-color',
    );
    const style = { visibility: isVisible ? 'visible' : 'hidden', ...position };

    return (
      <TooltipPortal>
        <div
          className={containerClassName}
          style={style}
          ref={this.setContainer}
        >
          <div className={styles.content} data-hook="tooltip">
            {children}
          </div>
          <div className={styles.triangleWrapper}>
            <div
              className={classNames(
                styles.triangle,
                'blog-card-border-color',
                'blog-card-background-color',
              )}
            />
          </div>
        </div>
      </TooltipPortal>
    );
  }
}

ButtonTooltip.propTypes = {
  style: PropTypes.object,
  contentFontClassName: PropTypes.string.isRequired,
  target: PropTypes.any.isRequired,
  isVisible: PropTypes.bool,
  children: PropTypes.node,
  isSite: PropTypes.bool,
};

const mapRuntimeToProps = (state, ownProps, actions, host) => ({
  isSite: isSite(state),
  componentId: host.id,
});

export default flowRight(
  withFontClassName,
  connect(mapRuntimeToProps),
)(ButtonTooltip);
