import React from 'react';
import AuthorWithPostDetails from '../../../post-page/components/author-with-post-details';
import { useFeedMetadataSettings } from '../../hooks/use-feed-metadata-settings';
import { NormalizedPost } from '../../types';
import Avatar from '../avatar';
import ProfileLink from '../link/profile-link';
import styles from './post-page-metadata-mobile.scss';

interface Props {
  post: NormalizedPost;
}

function PostPageMetadataMobile({ post }: Props) {
  const {
    isMetadataHeaderVisible,
    showAuthorName,
    showPublishDate,
    showReadingTime,
    showAuthorPicture,
  } = useFeedMetadataSettings({ post });

  return (
    <div className={styles.container}>
      {showAuthorName && showAuthorPicture && (
        <ProfileLink user={post.owner}>
          <Avatar user={post.owner} isNameVisible={false} />
        </ProfileLink>
      )}
      {isMetadataHeaderVisible && (
        <AuthorWithPostDetails
          post={post}
          showAuthorName={showAuthorName}
          showPublishDate={showPublishDate}
          showReadingTime={showReadingTime}
        />
      )}
    </div>
  );
}

export default PostPageMetadataMobile;
