import React, { PropsWithChildren } from 'react';
import classNames from 'classnames';
import { ArrowDownIcon } from '../icons/arrow-down-icon';
import styles from './change-category-trigger.scss';

const ChangeCategoryTrigger: React.FC<PropsWithChildren<{ title: string }>> = ({
  children,
  title,
}) => {
  const className = classNames(
    styles.container,
    'blog-card-background-color',
    'blog-text-color',
    'categories-button',
    styles.boxShadow,
  );

  return (
    <div className={className}>
      <div className={styles.wrapper}>
        <span data-hook="category-title" className={styles.title}>
          {title}
        </span>
        <ArrowDownIcon className={classNames('blog-icon-fill', styles.icon)} />
        {children}
      </div>
    </div>
  );
};

export default ChangeCategoryTrigger;
