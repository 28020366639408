import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import styles from './post-icon-row.scss';

const PostIconRow = ({ children, postId, className }) => {
  const id = `post-social-actions_${postId}`;

  return (
    <div
      className={classNames(styles.root, 'blog-text-color', className)}
      id={id}
      tabIndex={-1}
    >
      {children}
    </div>
  );
};

PostIconRow.propTypes = {
  postId: PropTypes.string.isRequired,
  className: PropTypes.string,
  children: PropTypes.node,
};

export default PostIconRow;
