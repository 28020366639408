import { isEqual } from 'lodash';
import { NormalizedCategory } from '@wix/communities-blog-client-common';

export const LINK_PADDING = 40;

let moreButtonWidth = 0;

export const selectors = {
  topNavigationContainer: '.blog-header__navigation',
  navigationListContainer: '.blog-header__navigation ul',
  moreButton: '#categories-more-button',
};

export const calculateHeaderItems = (
  categories: NormalizedCategory[],
  currentVisibleCategories: NormalizedCategory[],
) => {
  if (typeof window === 'undefined') {
    return;
  }

  /*
   Initially all links are rendered with more button. They are used to calculate how many
   links can fit inside he header. moreButtonWidth is used for editor when widget width or margin
   can change and we need to recalculate how many links fit in header. LINK_PADDING should be the
   same number as in css rules.
   */
  const topNavigationContainer = document.querySelector<HTMLElement>(
    selectors.topNavigationContainer,
  );
  const navigationListContainer = document.querySelector<HTMLElement>(
    selectors.navigationListContainer,
  );
  if (!topNavigationContainer) {
    return;
  }
  const moreButton = document.querySelector<HTMLElement>(selectors.moreButton);
  if (moreButton) {
    moreButtonWidth = moreButton.getBoundingClientRect().width;
  }

  const links = navigationListContainer?.children
    ? Array.from(navigationListContainer.children)
    : [];

  const linkElements = links.slice(
    0,
    moreButton ? links.length - 1 : undefined,
  );

  let widthLeft =
    topNavigationContainer.getBoundingClientRect().width -
    (moreButtonWidth + LINK_PADDING);

  const visibleCategories = [];
  for (const [index, link] of linkElements.entries()) {
    widthLeft -=
      link.getBoundingClientRect().width + (index > 0 ? LINK_PADDING : 0);
    if (index === 0) {
      continue;
    }
    if (widthLeft < 0) {
      break;
    } else {
      visibleCategories.push(categories[index - 1]);
    }
  }

  if (!isEqual(visibleCategories, currentVisibleCategories)) {
    const visibleLinks = linkElements.slice(0, visibleCategories.length + 1);
    const spaceLeft =
      topNavigationContainer.getBoundingClientRect().width -
      visibleLinks.reduce(
        (acc, val, index) =>
          acc +
          val.getBoundingClientRect().width +
          (index === 0 ? 0 : LINK_PADDING),
        0,
      );

    return {
      visibleCategories,
      moreCategoriesMenuWidth: spaceLeft,
      moreCategories: categories.slice(visibleCategories.length),
    };
  }

  return undefined;
};
