import React from 'react';
import classNames from 'classnames';
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import Link from '../../../common/components/link/internal-link';
import withTranslate from '../../../common/hoc/with-translate';
import RecentPostList from '../recent-posts-list';
import styles from './recent-posts-desktop.scss';

const RecentPostsDesktop = ({ t, posts, title, showAllPostsLink }) =>
  isEmpty(posts) ? null : (
    <div
      className={classNames(styles.container, 'blog-text-color')}
      data-hook="recent-posts"
    >
      {title && (
        <div className={styles.header}>
          <h2 className={styles.title}>{title}</h2>
          {showAllPostsLink && (
            <Link
              to="/"
              className={styles.link}
              data-hook="recent-posts-desktop__link"
            >
              {t('recent-posts.all-posts-link')}
            </Link>
          )}
        </div>
      )}
      <div className={title ? styles.list : ''}>
        <RecentPostList posts={posts} />
      </div>
    </div>
  );

RecentPostsDesktop.propTypes = {
  t: PropTypes.func,
  posts: PropTypes.array.isRequired,
  showAllPostsLink: PropTypes.bool.isRequired,
};

export default withTranslate(RecentPostsDesktop);
