import React from 'react';
import classNames from 'classnames';
import PostMetadata from '../../../post-page/components/post-metadata';
import PostMetadataLayout from '../../../post-page/components/post-metadata-layout';
import { useFeedMetadataSettings } from '../../hooks/use-feed-metadata-settings';
import { NormalizedPost } from '../../types';
import Avatar from '../avatar';
import ProfileLink from '../link/profile-link';
import styles from './post-page-metadata-desktop.scss';

interface Props {
  post: NormalizedPost;
  className?: string;
  linkClassName?: string;
}

const PostPageMetadataDesktop: React.FC<Props> = ({
  post,
  className,
  linkClassName,
}) => {
  const settings = useFeedMetadataSettings({ post });

  return (
    <PostMetadataLayout className={classNames(styles.container, className)}>
      {settings.showAuthorName && (
        <ProfileLink user={post.owner} className={linkClassName}>
          <Avatar
            user={post.owner}
            isImageVisible={settings.showAuthorPicture}
          />
        </ProfileLink>
      )}
      {(settings.showPublishDate || settings.showReadingTime) && (
        <PostMetadata
          date={post.firstPublishedDate || new Date()}
          readTime={post.timeToRead}
          isShortReadTimeLabel={false}
          showPublishDate={settings.showPublishDate}
          showReadingTime={settings.showReadingTime}
        />
      )}
    </PostMetadataLayout>
  );
};

export default PostPageMetadataDesktop;
