import React from 'react';
import classNames from 'classnames';
import { map, flowRight } from 'lodash';
import PropTypes from 'prop-types';
import { resolveId } from '@wix/communities-blog-client-common';

import { usePostBorderWidth } from '../../../common/hooks/use-post-border-width';
import RecentPostsListItem from '../recent-posts-list-item';
import styles from './recent-posts-list.scss';
import { useFeedMetadataSettings } from '../../../common/hooks/use-feed-metadata-settings';

export const RecentPostsList = ({
  posts = [],
}) => {
  const { borderWidth } = usePostBorderWidth();
  const { isMetadataFooterVisible } = useFeedMetadataSettings({});

  const itemClassName = classNames(
    styles.listItem,
    'blog-card-background-color',
    'blog-card-border-color',
    'recent-posts-list__list-item',
  );

  return (
    <div className={styles.container}>
      {map(posts, (post) => (
        <div
          className={itemClassName}
          style={{ borderWidth }}
          key={resolveId(post)}
        >
          <RecentPostsListItem
            post={post}
            isMetadataFooterVisible={isMetadataFooterVisible}
          />
        </div>
      ))}
    </div>
  );
};

RecentPostsList.propTypes = {
  posts: PropTypes.array,
};

export default RecentPostsList;
