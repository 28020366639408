import React, { Component } from 'react';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import FastFormContext from './fast-form-context';

/*
withFastForm({
 formName: 'comments' || ownProps.formName,
 formInitialValues: {} || ownProps.formInitialValues,
 validate: (fields) => ({errors}),
 resetOnUnmount: false
})
props.fastForm.changeValue('title')(value)
props.fastForm.changeField('title', { active: true })
props.fastForm.resetForm()
props.fastForm.values.title
props.fastForm.fields.title
props.fastForm.errors
props.fastForm.isValid
props.fastForm.isSubmitting
props.fastForm.formCommitTime
props.fastForm.formInitTime
props.fastForm.submit() -> invokes onSubmit on ownProps or cb
props.fastForm.stopSubmit() -> changes isSubmitting state to false
props.fastForm.changeValues(options, { title: 'my' }) -> change value of any form
*/

const normalizeValue = (value) => {
  if (get(value, 'target.type') === 'checkbox') {
    return value.target.checked;
  }
  return get(value, 'target.value', value);
};

class FastFormProvider extends Component {
  state = {};

  static initEmptyFormState = (formFields = [], api) => ({
    values: {},
    errors: {}, // set on value change
    fields: formFields.reduce((acc, name) => {
      acc[name] = {
        name,
        active: false,
        onChange: api.changeValue(name),
        onFocus: api.changeField(name, { active: true }),
        onBlur: api.changeField(name, { active: false }),
      };
      return acc;
    }, {}),
    isValid: true,
    isSubmitting: false,
    formCommitTime: Date.now(),
    formInitTime: Date.now(),
  });

  static applyValueChangeToState = (
    formState,
    validate,
    values,
    setInitialValue,
  ) => {
    const nextState = {
      ...formState,
      values: {
        ...formState.values,
      },
      fields: {
        ...formState.fields,
      },
    };

    for (const [name, val] of Object.entries(values)) {
      const value = normalizeValue(val);
      const field = { ...nextState.fields[name], name, value };
      if (setInitialValue) {
        field.initialValue = value;
      }
      nextState.values[name] = value;
      nextState.fields[name] = field;
    }

    if (validate) {
      nextState.errors = validate(nextState.values) || {};
    }

    nextState.isValid = Object.keys(nextState.errors).length === 0;
    nextState.formCommitTime = Date.now();
    return nextState;
  };

  static applyFieldChangeToState = (formState, name, props) => ({
    ...formState,
    fields: {
      ...formState.fields,
      [name]: { ...formState.fields[name], ...props },
    },
  });

  componentDidUpdate(prevProps) {
    if (
      this.props.submitAction &&
      get(prevProps.submitAction, 'timestamp') !==
        get(this.props.submitAction, 'timestamp')
    ) {
      this.submitForm(this.props.submitAction.formName);
    }
  }

  getApi = (options, ownProps, config) => {
    const formName = ownProps.formName || options.formName;

    let formState = this.state[formName];
    const api = {
      submit: (cb) =>
        this.submitForm(
          formName,
          ownProps.onSubmit || cb,
          this.getApi(options, ownProps, config),
        ),
      stopSubmit: () => {
        const currentFormState = this.state[formName];

        if (!currentFormState) {
          return console.error(
            `cannot stop submit ${formName} - form doesn't exist`,
          );
        }

        if (!currentFormState.isSubmitting) {
          return;
        }

        this.setState({
          [formName]: {
            ...currentFormState,
            isSubmitting: false,
          },
        });
      },
      changeValue: (name) => (value, cb) => {
        const currentFormState = this.state[formName] || formState;
        if (currentFormState.isSubmitting) {
          return;
        }
        this.setState(
          {
            [formName]: FastFormProvider.applyValueChangeToState(
              currentFormState,
              options.validate,
              { [name]: value },
            ),
          },
          cb,
        );
      },
      changeField: (name, props) => () => {
        const currentFormState = this.state[formName] || formState;
        if (currentFormState.isSubmitting) {
          return;
        }
        this.setState({
          [formName]: FastFormProvider.applyFieldChangeToState(
            currentFormState,
            name,
            props,
          ),
        });
      },
      changeValues: (options, values, cb) => {
        const currentFormState = this.state[options.formName];

        if (!currentFormState) {
          return console.error(
            `cannot change ${options.formName} - form doesn't exist`,
          );
        }

        if (currentFormState.isSubmitting) {
          return;
        }

        const nextState = FastFormProvider.applyValueChangeToState(
          currentFormState,
          options.validate,
          values,
        );
        this.setState(
          { [options.formName]: nextState },
          () => cb && cb(nextState.values),
        );
      },
      resetForm: () => this.resetForm(formName),
      ...formState,
    };

    if (!formState) {
      formState = FastFormProvider.initEmptyFormState(options.fields, api);
      if (!config.skipInitialize) {
        const initialValues =
          options.formInitialValues || ownProps.formInitialValues || {};
        formState = FastFormProvider.applyValueChangeToState(
          formState,
          options.validate,
          initialValues,
          true,
        );
        Object.assign(api, formState);
        this.setState({ [formName]: formState });
      }
    }

    return api;
  };

  resetForm = (formName) => {
    this.setState({ [formName]: undefined });
  };

  submitForm = (formName, cb, api) => {
    const currentFormState = this.state[formName];

    if (!currentFormState) {
      return console.error(`cannot submit ${formName} - form doesn't exist`);
    }

    if (currentFormState.isSubmitting) {
      return;
    }
    const formState = {
      ...currentFormState,
      isSubmitting: true,
    };

    if (cb) {
      cb(formState, api);
    }

    this.setState({ [formName]: formState });
    this.props.onSubmit && this.props.onSubmit(formName, formState); // transmit to redux
  };

  render() {
    return (
      <FastFormContext.Provider
        value={{ getApi: this.getApi, resetForm: this.resetForm }}
      >
        {this.props.children}
      </FastFormContext.Provider>
    );
  }
}

FastFormProvider.propTypes = {
  submitAction: PropTypes.object,
  onSubmit: PropTypes.func,
};

export default FastFormProvider;
