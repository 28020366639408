import {
  NormalizedCategory,
  resolveId,
} from '@wix/communities-blog-client-common';

export type HeaderLink = {
  key: string;
  path: string;
  text: string;
};

type Params = {
  categories: NormalizedCategory[];
  feedTitle: string;
  categoryPath: string;
  withLinkToAllPosts: boolean;
};

export const getHeaderLinks = ({
  categories,
  feedTitle,
  categoryPath,
  withLinkToAllPosts,
}: Params): HeaderLink[] => {
  const linkToAllPosts: HeaderLink = {
    key: 'latest_posts',
    path: '',
    text: feedTitle,
  };
  const linksToCategories: HeaderLink[] = categories.map((category) => ({
    key: resolveId(category),
    path: `/${categoryPath}/${category.slug}`,
    text: category.menuLabel,
  }));

  return withLinkToAllPosts
    ? [linkToAllPosts, ...linksToCategories]
    : linksToCategories;
};
