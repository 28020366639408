import { TagOrder, TagAlignment } from '@wix/communities-blog-client-common';

export const getTagTextAlignment = (tagAlignment: TagAlignment) => {
  switch (tagAlignment) {
    case TagAlignment.Left:
      return 'left';
    case TagAlignment.Center:
      return 'center';
    case TagAlignment.Right:
      return 'right';
    default:
      return 'left';
  }
};

export const sortTags = <
  T extends {
    label: string;
    count: number;
  },
>(
  tags: T[],
  tagOrder: TagOrder,
): T[] => {
  switch (tagOrder) {
    case TagOrder.Alphabetical:
      return [...tags].sort((a, b) => a.label.localeCompare(b.label));
    case TagOrder.MostUsed:
      return [...tags].sort((a, b) => {
        if (a.count < b.count) {
          return 1;
        }
        if (a.count > b.count) {
          return -1;
        }

        return a.label.localeCompare(b.label);
      });
    default:
      return tags;
  }
};
