import React from 'react';
import classNames from 'classnames';
import { flowRight } from 'lodash';
import {
  getIsAppBundle,
  getIsPostPageBundle,
} from '@wix/communities-blog-client-common';
import withBlogMenuSettings from '../../hoc/with-blog-menu-settings';
import { getPostBySlug } from '../../selectors/post-selectors';
import { AppState } from '../../types';
import DesktopHeader from '../desktop-header';
import HeaderNavigation from '../header-navigation';
import HeaderWidget from '../header-widget';
import { connect } from '../runtime-context';
import SearchInput from '../search-input';
import styles from './category-desktop-header.scss';

const renderSearchInput = (post: any) => (
  <SearchInput
    post={post}
    inputClassName="blog-desktop-header-search-text-color blog-desktop-header-search-font"
    borderClassName="blog-desktop-header-search-border-background-color"
    iconClassName="blog-desktop-header-search-icon-fill"
  />
);

type Props = {
  showSearch: boolean;
  showCategoryLabels: boolean;
  isAppBundle: boolean;
  isPostPageBundle: boolean;
  postSlug: string;
  post: any;
};

const CategoryDesktopHeader = (props: Props) => {
  const {
    showCategoryLabels,
    showSearch,
    isAppBundle,
    isPostPageBundle,
    post,
  } = props;

  return (
    <DesktopHeader
      className={classNames(
        styles.container,
        isAppBundle && styles.noMargin,
        isPostPageBundle && styles.inPostPage,
      )}
      left={showCategoryLabels ? <HeaderNavigation /> : <div />}
      right={
        <HeaderWidget
          searchInput={showSearch ? renderSearchInput(post) : null}
        />
      }
      {...props}
    />
  );
};

const mapRuntimeToProps = (state: AppState, { postSlug }: Props) => ({
  isAppBundle: getIsAppBundle(state),
  isPostPageBundle: getIsPostPageBundle(state),
  post: postSlug ? getPostBySlug(state, postSlug) : null,
});

export default flowRight(
  withBlogMenuSettings,
  connect(mapRuntimeToProps),
)(CategoryDesktopHeader);
