import { createStylesParams } from '@wix/tpa-settings';
import { CustomCssVarsFn } from '@wix/yoshi-flow-editor';
import {
  ContentSizing,
  postContentStyleParams,
} from '@wix/communities-blog-client-common';

export const customCssVars: CustomCssVarsFn = (tpaParams) => {
  return {
    'postContent-contentWidthInPxOrPercent': contentWidth(),
  };

  function contentWidth(): `${number}px` | `${number}%` {
    const contentSizing: ContentSizing =
      tpaParams.styleParams.numbers[
        postContentStyleParams['postContent-contentSizing'].key
      ];
    const contentSizingInPx =
      tpaParams.styleParams.numbers[
        postContentStyleParams['postContent-contentWidthInPx'].key
      ];
    const contentSizingInPercent =
      tpaParams.styleParams.numbers[
        postContentStyleParams['postContent-contentWidthInPercent'].key
      ];

    return contentSizing === ContentSizing.Fixed
      ? `${contentSizingInPx}px`
      : `${contentSizingInPercent}%`;
  }
};

export type IStylesParams = {};

export default createStylesParams<IStylesParams>({});
