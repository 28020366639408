import React, { FocusEventHandler, useEffect } from 'react';
import classNames from 'classnames';

import { useTranslation } from '@wix/yoshi-flow-editor';
import useIsFeedDesignEnabled from '../../hooks/use-is-feed-design-enabled';
import { HeaderLink } from '../../services/get-header-links';
import Wix from '../../services/wix-sdk-polyfill';
import { ArrowDownIcon } from '../icons/arrow-down-icon';
import Link from '../link/internal-link';

import styles from './more-categories-dropdown.scss';

type Props = DropdownItemsProps & {};

const MoreCategoriesDropdown: React.FC<Props> = (props) => {
  const { t } = useTranslation();
  const [showItems, setShowItems] = React.useState(false);
  const { applyFeedDesign } = useIsFeedDesignEnabled();

  const setHeightIfNeeded = () => {
    const headerAndDropdownHeight = getHeaderAndDropdownHeight();
    if (headerAndDropdownHeight > document.body.offsetHeight) {
      Wix.setHeight(headerAndDropdownHeight);
    }
  };

  const handleShowItems = () => {
    setShowItems(true);
  };

  const handleHideItems = () => {
    setShowItems(false);
  };

  const handleHideItemsOnBlur: FocusEventHandler = (event) => {
    if (
      event.relatedTarget &&
      !event.currentTarget.contains(event.relatedTarget)
    ) {
      handleHideItems();
    }
  };

  useEffect(() => {
    if (showItems === true) {
      setHeightIfNeeded();
    }
  }, [showItems]);

  return (
    <div
      className={styles.link}
      onMouseLeave={handleHideItems}
      onBlur={handleHideItemsOnBlur}
    >
      <span
        onMouseEnter={handleShowItems}
        onFocus={handleShowItems}
        className={styles.dropdownLink}
        tabIndex={0}
      >
        <span>{t('header.more-button')}</span>
        {applyFeedDesign ? (
          <span
            style={{ position: 'relative', width: '1em', marginLeft: '0.2em' }}
          >
            <ArrowDownIcon
              style={{ width: '1em', position: 'absolute', bottom: '0.2em' }}
            />
          </span>
        ) : (
          <ArrowDownIcon height="12" style={{ float: 'right', marginTop: 4 }} />
        )}
      </span>
      {showItems && (
        <MoreCategoriesDropdownMenu
          currentPath={props.currentPath}
          items={props.items}
          width={props.width}
          onNavigation={props.onNavigation}
        />
      )}
    </div>
  );
};

type DropdownItemsProps = {
  items: HeaderLink[];
  currentPath: string;
  width: number | undefined;
  onNavigation: (item: HeaderLink) => void;
};

const MoreCategoriesDropdownMenu: React.FC<DropdownItemsProps> = (props) => {
  return (
    <div
      className={styles.container}
      data-hook="blog-desktop-header-dropdown-menu-container"
    >
      <div
        className={classNames(
          styles.dropdownContainer,
          'blog-categories-dropdown-background-color',
        )}
        style={{ maxWidth: props.width }}
      >
        {props.items.map((item) => {
          const isActive = item.path === props.currentPath;

          return (
            <div className={classNames(styles.dropdownItem)}>
              <div
                className={classNames(
                  styles.text,
                  'blog-navigation-container-color',
                )}
              >
                <Link
                  to={item.path}
                  isActive={isActive}
                  addHoverClasses={false}
                  className={classNames(styles.link, {
                    [styles.active]: isActive,
                  })}
                  onNavigation={() => props.onNavigation(item)}
                >
                  {item.text}
                </Link>
              </div>
              <div
                className={classNames(
                  styles.separator,
                  'blog-navigation-container-separator-background-color',
                )}
              />
            </div>
          );
        })}
      </div>
    </div>
  );
};

const getHeaderAndDropdownHeight = () => {
  const menuHeight =
    document.querySelector<HTMLElement>(
      '[data-hook="blog-desktop-header-container"]',
    )?.offsetHeight ?? 0;
  const dropdownElementHeight =
    document.querySelector<HTMLElement>(
      '[data-hook="blog-desktop-header-dropdown-menu-container"]',
    )?.offsetHeight ?? 0;

  return dropdownElementHeight + menuHeight;
};

export default MoreCategoriesDropdown;
