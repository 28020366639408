import { useSelector } from '../components/runtime-context';
import { getCurrentMatch } from './router-selectors';

export type MatchedRoute = ReturnType<typeof getCurrentMatch>;

export const useMatchedRoute = () =>
  useSelector(
    (state): MatchedRoute => ({
      ...getCurrentMatch(state),
    }),
  );
