import React from 'react';
import classnames from 'classnames';
import { ButtonData_Type, Link_Target, RichContent } from '@wix/ricos';
import { PaywallBanner } from '@wix/communities-blog-client-common/dist/esm/components';
import RichContentViewer from '../../../rich-content/components/rich-content-viewer';
import useFontClassName from '../../hooks/use-font-class-name';
import { getAppSettings } from '../../selectors/app-settings-base-selectors';
import { getSection } from '../../selectors/section-selectors';
import { getTopology } from '../../store/topology/topology-selectors';
import type { NormalizedPost } from '../../types';
import { useSelector } from '../runtime-context';
import styles from './post-content.scss';

type PaywallBannerWithLinkProps = {
  paywallContent: RichContent;
};

const PaywallBannerWithLink: React.FC<PaywallBannerWithLinkProps> = ({
  paywallContent,
}) => {
  const appSettings = useSelector(getAppSettings);
  const section = useSelector(getSection);
  const pricingPlansUrl = useSelector((state) => state.pricingPlansUrl);
  const { baseUrl } = useSelector(getTopology);
  const subscribeURL = React.useMemo(() => {
    try {
      if (baseUrl && typeof pricingPlansUrl === 'string') {
        return new URL(baseUrl + pricingPlansUrl).href;
      }
    } catch {
      return undefined;
    }
  }, [baseUrl, pricingPlansUrl]);

  const modifiedPaywallContent = React.useMemo(() => {
    try {
      if ('nodes' in paywallContent) {
        const buttonNode = paywallContent.nodes[2];

        if (buttonNode.buttonData && !buttonNode.buttonData?.link) {
          buttonNode.buttonData.type = ButtonData_Type.LINK;
          const link = {
            url: subscribeURL,
            anchor: undefined,
            target: Link_Target.SELF,
            rel: {
              nofollow: true,
              sponsored: false,
            },
          };

          buttonNode.buttonData.link = link;
        }

        return paywallContent;
      }
    } catch {
      return paywallContent;
    }
  }, [paywallContent, subscribeURL]);

  return (
    <PaywallBanner
      banner={modifiedPaywallContent}
      viewer={RichContentViewer}
      appSettings={appSettings}
      section={section}
      className={styles.banner}
    />
  );
};

type PostContentProps = {
  post: NormalizedPost;
};

export const PostContent: React.FC<PostContentProps> = ({ post }) => {
  const { contentFontClassName } = useFontClassName();

  const paywallContent = post?.paywallRichContent as RichContent;

  const postContentBodyClass = 'post-content__body';

  return (
    <article className={contentFontClassName}>
      <div className={classnames(postContentBodyClass, styles.rceNextView)}>
        {typeof post.content === 'object' && (
          <RichContentViewer
            initialRawState={post.content}
            post={post}
            parentClass={postContentBodyClass}
          />
        )}
      </div>
      {post?.canSeePaidContent === false && paywallContent && (
        <div className={styles.paywallContainer}>
          <PaywallBannerWithLink paywallContent={paywallContent} />
        </div>
      )}
    </article>
  );
};

export default PostContent;
