import React from 'react';
import classNames from 'classnames';
import styles from './dot.scss';

export const Dot = () => (
  <div className={styles.container}>
    <div className={classNames(styles.dot, 'blog-text-background-color')} />
    <div
      className={classNames(
        styles.dot,
        styles.active,
        'blog-button-background-color',
      )}
    />
  </div>
);
