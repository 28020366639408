import React from 'react';
import loadable from '@wix/yoshi-flow-editor/loadable';
import Loader from '../../../common/components/loader';

export default loadable(
  () =>
    import(
      /* webpackChunkName: "post-editor" */
      './post-editor'
    ),
  {
    fallback: <Loader />,
  },
);
