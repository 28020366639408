import React from 'react';

export type FastFormApiOptions = {
  resetOnUnmount?: boolean;
  formName?: string;
  formInitialValues?: any;
  validate?: (values: any) => { errors: any };
};

export type FastFormApiConfig = {
  resetOnUnmount?: boolean;
  skipInitialize?: boolean;
};

type FastFormApi = {
  resetForm: (formName?: string) => void;
};

type FastFormContext = {
  getApi: (
    options: FastFormApiOptions,
    props: any,
    config: FastFormApiConfig,
  ) => FastFormApi;
};

export default React.createContext<FastFormContext>({
  getApi: (options, props, config) => ({
    resetForm: () => {},
  }),
});
