import React from 'react';
import classNames from 'classnames';
import { useTranslation } from '@wix/yoshi-flow-editor';
import { SearchIcon } from '../icons/search-icon';
import Link from '../link/internal-link';
import styles from './search-button.scss';

const SearchButton = () => {
  const { t } = useTranslation();

  const iconClassName = 'blog-button-primary-icon-fill';

  return (
    <Link to="/search" className={classNames(styles.button, 'search-button')}>
      <SearchIcon className={iconClassName} />
      <span className={styles.hiddenLabel}>{t('search-button.search')}</span>
    </Link>
  );
};

export default SearchButton;
