import React from 'react';
import PropTypes from 'prop-types';
import { connect } from '../../../common/components/runtime-context';
import { getCurrentMatch } from '../../../common/router/router-selectors';
import { getPostBySlug } from '../../../common/selectors/post-selectors';
import { isInPostPage } from '../../../common/services/detect-route';
import { resolvePostSlug } from '../../../common/services/slug';
import { getHasReportedAppLoad } from '../../../common/store/app-loaded/app-loaded-selectors';
import { isSSR } from '../../../common/store/basic-params/basic-params-selectors';

class PostPushNotifications extends React.Component {
  post = null;

  componentDidUpdate(prevProps) {
    const {
      emitOpenPost,
      emitClosePost,
      isAppLoaded,
      isSSR,
      currentPathname,
      currentPost,
    } = this.props;

    if (isSSR) {
      return;
    }

    if (this.post && currentPathname !== prevProps.currentPathname) {
      emitClosePost(this.post);
      this.post = null;
    }

    if (!this.post && currentPost && isAppLoaded) {
      this.post = currentPost;
      emitOpenPost(this.post);
    }
  }

  render() {
    return null;
  }
}

PostPushNotifications.propTypes = {
  emitOpenPost: PropTypes.func.isRequired,
  emitClosePost: PropTypes.func.isRequired,
  isAppLoaded: PropTypes.bool.isRequired,
  isSSR: PropTypes.bool.isRequired,
  currentPathname: PropTypes.string.isRequired,
  currentPost: PropTypes.object,
};

const mapRuntimeToProps = (state, _, actions) => {
  const currentMatch = getCurrentMatch(state);
  const getCurrentPost = () => {
    if (isInPostPage(currentMatch.route)) {
      const postSlug = resolvePostSlug(currentMatch.params);
      return getPostBySlug(state, postSlug);
    }
  };

  return {
    emitOpenPost: actions.emitOpenPost,
    emitClosePost: actions.emitClosePost,
    isAppLoaded: getHasReportedAppLoad(state),
    isSSR: isSSR(state),
    currentPathname: currentMatch.pathname,
    currentPost: getCurrentPost(),
  };
};

export default connect(mapRuntimeToProps)(PostPushNotifications);
