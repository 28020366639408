import { createSelector } from 'reselect';
import { getCategoryPath } from '@wix/communities-blog-client-common';
import { getCurrentMatchPathname } from '../router/router-selectors';
import { getHeaderLinks } from '../services/get-header-links';
import { getCategories } from '../store/categories/categories-selectors';
import { getViewerAllPostsLabelTranslation } from './viewer-all-post-label-translation-selectors';

export const getFeedAndCategoryLinks = createSelector(
  [
    getCategories,
    getViewerAllPostsLabelTranslation,
    getCategoryPath,
    (state) => state?.tpaSettings?.settings?.allPostsFeedLabel,
  ],
  (
    categories,
    translatedFeedTitle,
    categoryPath,
    allPostsFeedLabelFromSettings,
  ) =>
    getHeaderLinks({
      categories,
      feedTitle: translatedFeedTitle || allPostsFeedLabelFromSettings,
      categoryPath,
      withLinkToAllPosts: true,
    }),
);

export const getActiveLink = createSelector(
  getFeedAndCategoryLinks,
  getCurrentMatchPathname,
  (links, currentPath) =>
    links.find((link) => link.path === currentPath) || links[0],
);
