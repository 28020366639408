import { flowRight } from 'lodash';
import { connect } from '../components/runtime-context';
// eslint-disable-next-line no-unused-vars
import useBlogMenuSettings from '../hooks/use-blog-menu-settings';

import {
  getIsBlogMenuEnabled,
  getIsBlogMenuCategoryLabelsEnabled,
  getIsBlogMenuSearchEnabled,
  getIsBlogMenuLoginButtonEnabled,
} from '../selectors/app-settings-selectors';
import withHocName from './with-hoc-name';

const mapRuntimeToProps = (state) => ({
  showBlogMenu: getIsBlogMenuEnabled(state),
  showCategoryLabels: getIsBlogMenuCategoryLabelsEnabled(state),
  showSearch: getIsBlogMenuSearchEnabled(state),
  showLoginButton: getIsBlogMenuLoginButtonEnabled(state),
});

/** @deprecated use {@link useBlogMenuSettings} hook instead */
const withBlogMenuSettings = flowRight(
  connect(mapRuntimeToProps),
  withHocName('WithBlogMenuSettings'),
);

export default withBlogMenuSettings;
