import React from 'react';
import { flowRight } from 'lodash';
import PropTypes from 'prop-types';

import {
  SHARE_BUTTON_TRANSLATION_KEYS,
  SOCIAL_SHARING_PRINT,
} from '@wix/communities-blog-client-common';

import PostShareButton from '../../../post-page/components/post-share-button';
import withTranslate from '../../hoc/with-translate';
import { connect } from '../runtime-context';

const PostPrintAction = ({ t, postId, postPageSocialShareClicked }) => {
  return (
    <PostShareButton
      type={SOCIAL_SHARING_PRINT}
      title={t(SHARE_BUTTON_TRANSLATION_KEYS[SOCIAL_SHARING_PRINT])}
      onClick={() => {
        postPageSocialShareClicked({
          postId,
          type: SOCIAL_SHARING_PRINT,
        });
        window.print();
      }}
    />
  );
};

PostPrintAction.propTypes = {
  postId: PropTypes.string.isRequired,
  postPageSocialShareClicked: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};

const mapRuntimeToProps = (state, ownProps, actions) => ({
  postPageSocialShareClicked: actions.postPageSocialShareClicked,
});

export default flowRight(
  connect(mapRuntimeToProps),
  withTranslate,
)(PostPrintAction);
