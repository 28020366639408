import React from 'react';
import PropTypes from 'prop-types';
import { getHasReportedAppLoad } from '../../store/app-loaded/app-loaded-selectors';
import { isSSR } from '../../store/basic-params/basic-params-selectors';
import { connect } from '../runtime-context';

class BlogPushNotifications extends React.Component {
  componentDidUpdate(prevProps) {
    const { emitBlogOpened, isAppLoaded, isSSR } = this.props;

    if (isSSR) {
      return;
    }

    if (isAppLoaded && !prevProps.isAppLoaded) {
      emitBlogOpened();
    }
  }

  render() {
    return null;
  }
}

BlogPushNotifications.propTypes = {
  emitBlogOpened: PropTypes.func.isRequired,
  isAppLoaded: PropTypes.bool.isRequired,
  isSSR: PropTypes.bool.isRequired,
};

const mapRuntimeToProps = (state, _, actions) => ({
  emitBlogOpened: actions.emitBlogOpened,
  isAppLoaded: getHasReportedAppLoad(state),
  isSSR: isSSR(state),
});

export default connect(mapRuntimeToProps)(BlogPushNotifications);
