import { useCallback, useEffect, useState } from 'react';
import { dateLib } from '@wix/communities-blog-client-common';
import useScrollListener, {
  ScrollListenerHandler,
} from '../../../common/hooks/use-scroll-listener';
import {
  calculateReadingTimeProps,
  ReadingTimeStats,
} from './reading-time-helpers';

export type OnScrollHandler = (
  event: ReadingTimeStats & {
    reading_session_id: string;
  },
) => void;

export type OnTabVisibilityChangeHandler = (event: {
  is_on: boolean;
  reading_session_id: string;
}) => void;

type Options = {
  getContentContainer: () => DOMRect | undefined;
  onScroll: OnScrollHandler;
  onTabVisibilityChange: OnTabVisibilityChangeHandler;
  readingSessionId: string;
};

const useReadingTimeListener = (options: Options) => {
  const [readingStartTime] = useState(dateLib.utc());
  const {
    getContentContainer,
    onScroll,
    onTabVisibilityChange,
    readingSessionId,
  } = options;

  const onFocus = useCallback(() => {
    onTabVisibilityChange({
      is_on: document.visibilityState === 'visible',
      reading_session_id: readingSessionId,
    });
  }, [readingSessionId, onTabVisibilityChange]);

  const handleScroll: ScrollListenerHandler = useCallback(
    (scroll) => {
      const pageHeight = scroll.target.documentElement.scrollHeight;
      const scrollPosition = scroll.target.documentElement.scrollTop;
      const clientHeight = scroll.target.documentElement.clientHeight;
      const browserWidth = scroll.target.documentElement.clientWidth;
      const scrollableHeight = pageHeight - clientHeight;

      if (scrollableHeight > 0) {
        onScroll({
          reading_session_id: readingSessionId,
          ...calculateReadingTimeProps({
            scrollPosition,
            clientHeight,
            scrollableHeight,
            browserWidth,
            readingStartTime,
            contentContainer: getContentContainer(),
          }),
        });
      }
    },
    [getContentContainer, onScroll, readingSessionId, readingStartTime],
  );

  useEffect(() => {
    document.addEventListener('visibilitychange', onFocus);

    return () => {
      document.removeEventListener('visibilitychange', onFocus);
    };
  }, [onFocus]);

  useScrollListener(handleScroll);
};

const ReadingTimeListener: React.FC<Options> = (props) => {
  useReadingTimeListener(props);

  return null;
};

export default ReadingTimeListener;
