const handleKeyUp = (keyCode, handler) => (event) => {
  event.keyCode === keyCode && handler(event);
};

export const handleEnterKeyUp = (handler) => (event) =>
  handleKeyUp(13, handler)(event);
export const handleEscapeKeyUp = (handler) => (event) =>
  handleKeyUp(27, handler)(event);
export const handleTabKeyUp = (handler) => (event) =>
  handleKeyUp(9, handler)(event);
