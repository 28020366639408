import React from 'react';
import classNames from 'classnames';
import Page, { PageProps } from '../../../common/components/page';
import forDevice from '../../../common/hoc/for-device';
import styles from './post-page-layout.scss';

const PostPageLayoutMobile: React.FC<PageProps> = ({
  children,
  ...otherProps
}) => <Page {...otherProps}>{children}</Page>;

const PostPageLayoutDesktop: React.FC<PageProps> = ({
  children,
  className,
  ...otherProps
}) => {
  return (
    <Page className={classNames(styles.container, className)} {...otherProps}>
      {children}
    </Page>
  );
};

export default forDevice(PostPageLayoutMobile, PostPageLayoutDesktop);
