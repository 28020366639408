import React, { useEffect } from 'react';
import useScrollListener from './use-scroll-listener';

const useOnScreen = (threshold = 200, isIgnored = false) => {
  const [isOnScreen, setIsOnScreen] = React.useState(false);
  const ref = React.useRef<Element>(null);

  const isElementOnScreen = React.useCallback(() => {
    const rect = ref.current?.getBoundingClientRect();

    return rect && rect.top < window.innerHeight + threshold;
  }, [threshold]);

  const handleScroll = React.useCallback(() => {
    if (isElementOnScreen()) {
      setIsOnScreen(true);
    }
  }, [isElementOnScreen]);

  useEffect(() => {
    handleScroll();
  }, [handleScroll]);

  useScrollListener(isIgnored ? undefined : handleScroll);

  return {
    isOnScreen: isIgnored ? true : isOnScreen,
    ref,
  };
};

export default useOnScreen;
