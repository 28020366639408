import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { TagLayout } from '@wix/communities-blog-client-common';
import { getTagLayout } from '../../../selectors/app-settings-selectors';
import { connect } from '../../runtime-context';
import styles from './tags-empty-state.scss';

export const TagsEmptyState = ({ tagLayout, emptyState }) => {
  const layoutClass =
    tagLayout === TagLayout.Button ? styles.buttonLayout : styles.textLayout;

  return (
    <div className={classNames(styles.container, layoutClass)}>
      {emptyState}
    </div>
  );
};

TagsEmptyState.propTypes = {
  emptyState: PropTypes.string.isRequired,
};

const mapRuntimeToProps = (state) => ({
  tagLayout: getTagLayout(state),
});

export default connect(mapRuntimeToProps)(TagsEmptyState);
