import React, { Component } from 'react';
import { flowRight } from 'lodash';
import PropTypes from 'prop-types';

import {
  getCustomPostUrl,
  getOuterUrl,
  MODAL_TYPE_SHARE_LINK,
  openShareDialog,
  SHARE_BUTTON_TRANSLATION_KEYS,
  SOCIAL_SHARING_LINK,
} from '@wix/communities-blog-client-common';

import PostShareButton from '../../../post-page/components/post-share-button';
import { BUTTON_SHARE_POST } from '../../bi-events/bi-buttons';
import withSectionUrl from '../../hoc/with-section-url';
import withTranslate from '../../hoc/with-translate';
import { isSite } from '../../store/basic-params/basic-params-selectors';
import { connect } from '../runtime-context';

export class PostSocialActions extends Component {
  url = () =>
    this.props.customPostUrl ||
    getOuterUrl(`/${this.props.postSlug}`, this.props.sectionUrl);

  handleShare = (provider) => {
    const {
      buttonClicked,
      postId,
      isSite,
      openModal,
      postPageSocialShareClicked,
    } = this.props;
    if (!isSite) {
      return;
    }

    postPageSocialShareClicked({ postId, type: provider });
    postId &&
      buttonClicked({ action: BUTTON_SHARE_POST, postId, shareType: provider });
    if (provider === SOCIAL_SHARING_LINK) {
      openModal(MODAL_TYPE_SHARE_LINK, { url: this.url() });
    } else {
      openShareDialog(provider, this.url());
    }
  };

  render() {
    const { t, isSite, enabledProviders } = this.props;
    const tooltipText = isSite
      ? ''
      : t('post-social-actions.preview-mode-tooltip');

    return (
      <>
        {enabledProviders.map((provider) => (
          <PostShareButton
            key={provider}
            type={provider}
            title={t(SHARE_BUTTON_TRANSLATION_KEYS[provider])}
            onClick={this.handleShare}
            tooltipText={tooltipText}
          />
        ))}
      </>
    );
  }
}

PostSocialActions.propTypes = {
  postId: PropTypes.string.isRequired,
  openModal: PropTypes.func.isRequired,
  buttonClicked: PropTypes.func.isRequired,
  postPageSocialShareClicked: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  isSite: PropTypes.bool,
  sectionUrl: PropTypes.string,
  enabledProviders: PropTypes.array.isRequired,
  customPostUrl: PropTypes.string.isRequired,
  postSlug: PropTypes.string.isRequired,
};

const mapRuntimeToProps = (state, ownProps, actions) => ({
  isSite: isSite(state),
  openModal: actions.openModal,
  buttonClicked: actions.buttonClicked,
  postPageSocialShareClicked: actions.postPageSocialShareClicked,
  customPostUrl: getCustomPostUrl(state, ownProps.postSlug),
});

export default flowRight(
  connect(mapRuntimeToProps),
  withTranslate,
  withSectionUrl,
)(PostSocialActions);
