import React from 'react';
import { useTranslation } from '@wix/yoshi-flow-editor';
import Tags from './tags';

type Props = Omit<React.ComponentProps<typeof Tags>, 'translations'>;

const TagsWithTranslation = (props: Props) => {
  const { t } = useTranslation();

  const translations = {
    tagsLabel: t('post-page.tags-label'),
    emptyState: t('empty-state.no-tags'),
    tags: (props.tags || []).reduce((acc, val) => {
      acc[val.id] = {
        a11yText: t('tag-link.a11y-post-count', {
          count: val.publicationCount,
        }),
        postCount: t('tag-link.post-count', { count: val.publicationCount }),
      };
      return acc;
    }, {}),
  };
  return <Tags {...props} translations={translations} />;
};

export default TagsWithTranslation;
