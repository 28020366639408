import React from 'react';
import classNames from 'classnames';
import { flowRight } from 'lodash';
import PropTypes from 'prop-types';
import { resolveId } from '@wix/communities-blog-client-common';
import CommentCount from '../../../comments/components/comment-count';
import withAuth from '../../hoc/with-auth';
import withFeedMetadataSettings from '../../hoc/with-feed-metadata-settings';
import withPermissions from '../../hoc/with-permissions';
import {
  getIsPrintEnabled,
  getIsSocialSharingEnabled,
  getSocialSharingProviders,
} from '../../selectors/app-settings-selectors';
import { getFeedColorClassName } from '../../services/layout-config';
import {
  getCommentCount,
  getViewCount,
} from '../../store/post-counters/post-counters-selectors';
import CategoryLabelList from '../category-label-list';
import LikeButtonWithCount from '../like-button-with-count';
import PostLink from '../link/post-link';
import PostIconRow from '../post-icon-row';
import PostPrintAction from '../post-print-action';
import PostSocialActions from '../post-social-actions';
import { connect } from '../runtime-context';
import { HorizontalSeparator } from '../separator';
import ViewCount from '../view-count';
import styles from './post-main-actions-desktop.scss';

const PostMainActionsDesktop = ({
  canRender,
  hasSocialLinks,
  hasCategories,
  hasPrint,
  commentsLink,
  enabledProviders,
  forPublicUser,
  getPostClassName,
  layoutName,
  incrementPostLikeCount,
  post,
  showCommentCount,
  showLikeCount,
  showViewCount,
  viewCount,
  totalComments,
}) => {
  const showCommentCountOrNew = commentsLink || totalComments;
  const isMetadataVisible =
    showLikeCount ||
    (showCommentCount && showCommentCountOrNew) ||
    showViewCount;
  const hasActionSection = hasSocialLinks || hasPrint || hasCategories;
  const noSeparators = !hasActionSection && !isMetadataVisible;
  const commentCount = showCommentCount ? (
    <CommentCount
      showZero
      count={totalComments}
      tabIndex={commentsLink ? -1 : 0}
    />
  ) : null;

  return (
    <div
      className={classNames(
        styles.container,
        { [styles.noSeparators]: noSeparators },
        getPostClassName(
          'description-font',
          getFeedColorClassName(layoutName, 'description-color'),
        ),
      )}
      data-hook="post-main-actions-desktop"
    >
      {hasActionSection && [
        <HorizontalSeparator key="separator" />,
        <div
          key="div"
          className={classNames(styles.section, styles.mainSection)}
        >
          <PostIconRow postId={post.id}>
            {hasSocialLinks &&
              canRender('share', 'post', post, () => (
                <PostSocialActions
                  postSlug={post.slug}
                  postId={resolveId(post)}
                  enabledProviders={enabledProviders}
                />
              ))}
            {hasPrint && <PostPrintAction postId={resolveId(post)} />}
          </PostIconRow>
          {hasCategories && <CategoryLabelList post={post} />}
        </div>,
      ]}
      {isMetadataVisible && [
        <HorizontalSeparator key="separator" />,
        <div
          key="div"
          className={classNames(styles.section, styles.bottomSection)}
          data-hook="post-main-actions__stats"
        >
          <div data-hook="post-stats" className={styles.stats}>
            {showViewCount && <ViewCount count={viewCount} tabIndex={0} />}
            {showCommentCount &&
              (commentsLink ? (
                <PostLink
                  postLink={commentsLink}
                  postPath={post.slug}
                  className={classNames(
                    styles.comments,
                    getPostClassName('link-hashtag-hover-color'),
                  )}
                >
                  {commentCount}
                </PostLink>
              ) : (
                commentCount
              ))}
          </div>
          {showLikeCount && (
            <LikeButtonWithCount
              className={styles.likeButton}
              onClick={forPublicUser(() =>
                incrementPostLikeCount(resolveId(post)),
              )}
              entity={post}
            />
          )}
        </div>,
      ]}
    </div>
  );
};

PostMainActionsDesktop.propTypes = {
  hasSocialLinks: PropTypes.bool.isRequired,
  hasCategories: PropTypes.bool.isRequired,
  hasPrint: PropTypes.bool.isRequired,
  canRender: PropTypes.func.isRequired,
  commentsLink: PropTypes.string,
  enabledProviders: PropTypes.array.isRequired,
  forPublicUser: PropTypes.func,
  getPostClassName: PropTypes.func.isRequired,
  layoutName: PropTypes.string,
  incrementPostLikeCount: PropTypes.func.isRequired,
  post: PropTypes.object.isRequired,
  showCategoryLabels: PropTypes.bool,
  showCommentCount: PropTypes.bool,
  showLikeCount: PropTypes.bool,
  showViewCount: PropTypes.bool,
  t: PropTypes.func,
  viewCount: PropTypes.number.isRequired,
  totalComments: PropTypes.number.isRequired,
};

const mapStateToProps = (
  state,
  { canSee, post, showCategoryLabels },
  actions,
) => {
  const isSocialSharingEnabled = getIsSocialSharingEnabled(state);
  const enabledProviders = getSocialSharingProviders(state);

  return {
    hasPrint: isSocialSharingEnabled && getIsPrintEnabled(state),
    enabledProviders,
    hasSocialLinks:
      canSee('share', 'post', post) && enabledProviders.length > 0,
    hasCategories: showCategoryLabels && post.categoryIds?.length > 0,
    incrementPostLikeCount: actions.incrementPostLikeCount,
    viewCount: getViewCount(state, resolveId(post)),
    totalComments: getCommentCount(state, resolveId(post)),
  };
};

export default flowRight(
  withPermissions,
  withFeedMetadataSettings,
  connect(mapStateToProps),
  withAuth,
)(PostMainActionsDesktop);
