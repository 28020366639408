import React from 'react';
import loadable from '@wix/yoshi-flow-editor/loadable';
import type { NormalizedPost } from '../../../common/types';

const PostPageWixCommentsUnsafe = loadable(
  () =>
    import(
      /* webpackChunkName: "post-page-wix-comments" */ './post-page-wix-comments'
    ),
);

export const PostPageWixCommentsSeo: React.FC<{ post: NormalizedPost }> = (
  props,
) => {
  return <PostPageWixCommentsUnsafe {...props} />;
};
