import React from 'react';
import classNames from 'classnames';
import { flowRight, map } from 'lodash';
import Slider from 'react-slick';
import { resolveId } from '@wix/communities-blog-client-common';
import { connect } from '../../../common/components/runtime-context';
import withCardBorderWidth from '../../../common/hoc/with-card-border-width';
import withFeedMetadataSettings from '../../../common/hoc/with-feed-metadata-settings';
import { getIsRTL } from '../../../common/store/basic-params/basic-params-selectors';
import type { AppState } from '../../../common/types';
import RecentPostsListItem from '../recent-posts-list-item';
import { Dot } from './dot';
import styles from './recent-post-carousel.scss';

interface RecentPostCarouselProps {
  isRTL: boolean;
  posts: any[];
  borderWidth: number;
  isMetadataFooterVisible: boolean;
}

const RecentPostCarousel = ({
  borderWidth,
  isRTL,
  posts = [],
  isMetadataFooterVisible,
}: RecentPostCarouselProps) => (
  <div dir="ltr">
    <Slider
      arrows={false}
      className={styles.container}
      dots={true}
      useCSS={true}
      infinite={true}
      customPaging={Dot}
      centerMode={true}
      centerPadding="30px"
      slidesToShow={1}
      slidesToScroll={1}
    >
      {map(posts, (post) => (
        <div
          className={styles.slide}
          key={resolveId(post)}
          dir={isRTL ? 'rtl' : undefined}
        >
          <div
            className={classNames(styles.postWrapper, 'blog-card-border-color')}
            style={{ borderWidth }}
          >
            <RecentPostsListItem
              post={post}
              isMetadataFooterVisible={isMetadataFooterVisible}
            />
          </div>
        </div>
      ))}
    </Slider>
  </div>
);

const mapRuntimeToProps = (state: AppState) => ({
  isRTL: getIsRTL(state),
});

export default flowRight(
  withCardBorderWidth,
  connect(mapRuntimeToProps),
  withFeedMetadataSettings,
)(RecentPostCarousel);
